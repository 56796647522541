/**
 * Copyright 2021 TM9657 GmbH. All Rights Reserved.
 *
 * base skeleton
 */

import '../styles/globals.css';
import '../styles/index.css';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
const SnackbarProvider = dynamic(() => import('notistack').then((mod) => mod.SnackbarProvider));
import Head from 'next/head';
import { AnimatePresence } from 'framer-motion';
import { BreadcrumbJsonLd, DefaultSeo, LocalBusinessJsonLd, SocialProfileJsonLd } from 'next-seo';
import styled from "styled-components"
import ReactGA from "react-ga4";
ReactGA.initialize("G-3GDVS9HFRZ");

export function reportWebVitals({ id, name, label, value }) {
    // Use `window.gtag` if you initialized Google Analytics as this example:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
    ReactGA.event(name, {
        event_category:
            label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        event_label: id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
    })
    // window.gtag('event', name, {
    //     event_category:
    //         label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    //     value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    //     event_label: id, // id unique to current page load
    //     non_interaction: true, // avoids affecting bounce rate.
    // })
}

if (process.env.NEXT_PUBLIC_MODE !== 'dev') console.log = () => {};

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                {/*<link rel="preconnect" href="https://fonts.gstatic.com" />*/}
                {/*<link*/}
                {/*    href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap"*/}
                {/*    rel="stylesheet"*/}
                {/*></link>*/}
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
                />
                <meta name="title" content="CA9.io - Your Content Metaverse" />
                <meta
                    name="description"
                    content="CA9.io is YOUR Content Metaverse. Create your own Game, Virtual Chatroom, Simulation, Digital Twin, Digital Event or Architectural Design without Coding and Unreal Engine Support"
                />

                <meta name="format-detection" content="telephone=no" />

                <meta name="theme-color" content="#1E2128" />
            </Head>
            <DefaultSeo
                title="CA9.io - Your Content Metaverse"
                description="CA9.io is YOUR Content Metaverse. Create your own Game, Virtual Chatroom, Simulation, Digital Twin, Digital Event or Architectural Design without Coding and Unreal Engine Support"
                twitter={{
                    site: '@ca9_io',
                    cardType: 'summary_large_image',
                }}
                openGraph={{
                    type: 'website',
                    url: `https://ca9.io/`,
                    title: 'CA9.io - Your Content Metaverse',
                    description:
                        'CA9.io is YOUR Content Metaverse. Create your own Game, Virtual Chatroom, Simulation, Digital Twin, Digital Event or Architectural Design without Coding and Unreal Engine Support',
                    site_name: 'CA9.io',
                    images: [
                        {
                            url: 'https://cdn.ca9.io/branding/OG.webp',
                            width: 1200,
                            height: 627,
                            alt: 'Thumbnail',
                        },
                    ],
                }}
            />
            <SocialProfileJsonLd
                keyOverride="social-media"
                type="Organization"
                name="CA9.io Portal"
                url="https://ca9.io"
                sameAs={[
                    'https://discord.com/invite/KTWMrS2/',
                    'https://www.youtube.com/channel/UCK9VrcgW1P0RWaAjoL9sNPg',
                    'https://www.linkedin.com/company/ca9',
                    'https://twitter.com/ca9_io',
                    'https://github.com/CA9io',
                ]}
            />
            <BreadcrumbJsonLd
                keyOverride="bread-crumbs"
                itemListElements={[
                    {
                        position: 1,
                        name: 'General Information',
                        item: 'https://ca9.io',
                    },
                    {
                        position: 2,
                        name: 'Portal',
                        item: 'https://portal.ca9.io',
                    },
                    {
                        position: 3,
                        name: 'Store',
                        item: 'https://portal.ca9.io/store',
                    },
                    {
                        position: 4,
                        name: 'Documentation',
                        item: 'https://portal.ca9.io/docs',
                    },
                ]}
            />
            <LocalBusinessJsonLd
                keyOverride="business"
                type="Store"
                id="httsp://ca9.io"
                name="CA9.io Content Universe >> TM9657 GmbH"
                description="CA9.io is YOUR Content Metaverse. Create your own Game, Virtual Chatroom, Simulation, Digital Twin, Digital Event or Architectural Design without Coding and Unreal Engine Support"
                url="https://ca9.io"
                address={{
                    streetAddress: '',
                    addressLocality: 'Memmingen',
                    addressRegion: 'Bavaria',
                    postalCode: '87700',
                    addressCountry: 'DE',
                }}
                sameAs={['portal.ca9.io']}
            />
            {/** @ts-ignore */}
            <StyledWrapper>
                <SnackbarProvider maxSnack={3}>
                    <AnimatePresence exitBeforeEnter>
                        {' '}
                        <Component {...pageProps} />
                    </AnimatePresence>
                </SnackbarProvider>
            </StyledWrapper>
        </>
    );
}

const StyledWrapper= styled.div`

  html,
  body, #__next {
    width: 100vw;
    height: 100vh;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: #131418;
  }
  
  @font-face{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/Mulish-ExtraLight.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/Mulish-ExtraLightItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Mulish-Light.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Mulish-LightItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Mulish-Regular.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/Mulish-Medium.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/Mulish-MediumItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/Mulish-SemiBold.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/Mulish-SemiBoldItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Mulish-Bold.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Mulish-BoldItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/Mulish-ExtraBold.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/Mulish-ExtraBoldItalic.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(/fonts/Mulish-Black.ttf);
  }

  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(/fonts/Mulish-BlackItalic.ttf);
  }
`

export default MyApp;
